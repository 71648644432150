

  
  import FETCHING from '../../library/fetching'
  import DATAMASTER from '../../library/dataMaster'
  import UMUM from '../../library/umum'
  
  export default {
    data() {
      return {
  
        
      
  
    form : {
        id :'',
        master_permintaan :'',
        uraian :'',
        no :'',
        realisasi :'',
        jml_keluar :'',
        jml_lpj :'',
        keterangan :'',
        dd :'',
        mm :'',
        yy :'',
        tgl :'',
        type :'',
        unit_kerja :'',
        file :'',

      },

      unit_kerja : '', 
      unit_kerja_id : '',

      file_path: this.$store.state.url.URL_APP + "uploads/",

  

  
        // ====================================== CONTOH AUTOCOMPLETE ====================================
        autocomplete_db : '',
        // ====================================== CONTOH AUTOCOMPLETE ====================================
        
        filterku : {
            urusan_kode : '',
            tahun : '',
            unit_kerja : '',
            instansi : '',
        },

        tipeku: null,

  
  
      
        list_data : [],
        list_data_serapan : [],
  
        page_first: 1,
        page_last: 0,
        page_limit : 10,
        cari_value: "",
        cek_load_data : true,



        mdl_view_list_serapan : false,
  
  
        mdl_add: false,
        mdl_edit: false,
        mdl_hapus : false,
        mdl_open : false,
        btn_add: false,
  
  
        FETCHING : FETCHING,
        DATAMASTER : DATAMASTER,
        UMUM : UMUM,
      }
    },
    methods: {
  
  
      getView : function(){
          this.$store.commit("shoWLoading");
          fetch(this.$store.state.url.URL_bku + "view", {
                  method: "POST",
                  headers: {
                  "content-type": "application/json",
                  authorization: "kikensbatara " + localStorage.token
                  },
                  body: JSON.stringify({
                      data_ke: this.page_first,
                      cari_value: this.cari_value,
                      page_limit : this.page_limit,
                      unit_kerja : this.unit_kerja_id,
                      unit_kerja_id : this.form.unit_kerja_id,
                      instansi : this.filterku.instansi,
                        unit_kerja : this.filterku.unit_kerja,
                        tahun : this.filterku.tahun,
                        tipeku : this.tipeku,
                  })
              })
              .then(res => res.json())
              .then(res_data => {
                  // this.list_data = res_data.data;
                  this.list_data = res_data;
                  console.log(res_data);
                  // this.page_last = res_data.jml_data;
                  this.$store.commit("hideLoading");
                  // console.log(res_data);
          });
          },
  
  
      addData: function(){

          var formData = new FormData();
          formData.append('pb', JSON.stringify(this.form));
          formData.append('file', this.form.file);

          fetch(this.$store.state.url.URL_rek + 'addData', {
              method: "POST",
              headers: {
                  authorization: "kikensbatara " + localStorage.token
              },
              body: formData
              }).then(res_data => {
              
              // this.lampiran.direktori_id
                  this.Notify ('Sukses Menambah Data', 'primary', 'check_circle_outline');
              this.getView();
          });
      },
  
  
      editData : function(){
        fetch(this.$store.state.url.CT_PROGRAM + "editData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {
            this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
            this.getView();
        });
      },
  
      removeData : function(E){
        fetch(this.$store.state.url.URL_rek + "removeData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
            this.getView();
        });
  
      },
  
      selectData : function(data){

        this.form.id = data.id ;
        this.form.master_permintaan = data.master_permintaan ;
        this.form.uraian = data.uraian ;
        this.form.no = data.no ;
        this.form.realisasi = data.realisasi ;
        this.form.jml_keluar = data.jml_keluar ;
        this.form.jml_lpj = data.jml_lpj ;
        this.form.keterangan = data.keterangan ;
        this.form.dd = data.dd ;
        this.form.mm = data.mm ;
        this.form.yy = data.yy ;
        this.form.tgl = data.tgl ;
        this.form.type = data.type ;
        this.form.unit_kerja = data.unit_kerja ;
        this.form.file = data.file ;


        this.viewSerapan();

          
      },

      viewSerapan : function(){
        fetch(this.$store.state.url.CT_REALISASI_KEUANGAN_SERAPAN + "getOne", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                realisasi_keuangan_id: this.form.id,
            })
        })
            .then(res => res.json())
            .then(res_data => {
              console.log(res_data);
                this.list_data_serapan = res_data;
                // this.TOTAL_SERAPAN = 0
                // res_data.forEach(element => {
                //   this.TOTAL_SERAPAN = this.TOTAL_SERAPAN + element.realisasi
                // });
        });
      },
  
  
      // ====================================== CONTOH eDOC ====================================
      async onChangexInstansi(){
        await DATAMASTER.getInstansiAsync("");
        this.onChangexUnitKerja();
      },
      async onChangexUnitKerja(){
        // console.log("yyyyyyyyyyyy");
        var unitkerjax = await DATAMASTER.getUnitKerjaAsync(this.filterku.instansi)
        // console.log("xxxxxxxxxxxxx");
        console.log(unitkerjax[0].id);
        this.filterku.unit_kerja = unitkerjax[0].id
        // console.log("xxxxxxxxxxxxx");
        console.log(this.filterku.unit_kerja);
        this.getView();
      },


      // async asyncFetchUrusan(){
      //   await DATAMASTER.postMasterUrusan('');
      //   this.form.urusan_kode = this.$store.state.list_urusan[0].kode;
      //   this.asyncFetchUrusanBidang(this.form.urusan_kode)
      // },
      // async asyncFetchUrusanBidang(val){
      //   await DATAMASTER.postMasterUrusanBidang(val)
      // },
      // ====================================== CONTOH eDOC ====================================
  
    // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_program_filter : function (val, update) {
          update(() => {
            if (val === '') {}
            else {DATAMASTER.postMasterProgramAutoComplete(val, this.form.unit_kerja)}
          })
      },
  
      // ====================================== CONTOH AUTOCOMPLETE ====================================
  
  
  
  
  
  
  
  
      // ====================================== PAGINATE ====================================
          Notify : function(message, positive, icon){
            this.$q.notify({
              message: message,
              color: positive,
              icon: icon,
              position : 'top',
              timeout: 500,
            })
          },
          btn_prev : function(){
              this.cek_load_data = true;
              if(this.page_first>1){
                  this.page_first--
              }else{
                  this.page_first = 1;
              }
              this.getView();
          },
  
          btn_next : function(){
              if(this.page_first >= this.page_last){
                  this.page_first == this.page_last
              }else{
                  this.page_first++;
              }
              this.getView();
          },
  
          cari_data : function(){
              this.page_first = 1;
              this.getView();
          },

            indexing : function(index){
                var idx = ((this.page_first-1)*this.page_limit)+index
                return idx
            },
  
  
      // ====================================== PAGINATE ====================================
  
  
  
  
  
  
  
    },
  
    mounted () {
      let profilex = JSON.parse(localStorage.profile);
        let profile = profilex.profile;
        // console.log("ASDASDA" + profile.unit_kerja);
        this.unit_kerja_id = profile.unit_kerja;
        this.form.unit_kerja_id = profile.unit_kerja;
        this.filterku.instansi = profile.instansi_id;

        this.tipeku = profile.trista_monev;
  
        const d = new Date();
        let year = d.getFullYear();
        this.form.tahun = year;
        this.filterku.tahun = year;
  
        console.log(profile)
  
  
        DATAMASTER.getTahun();
  
  
        DATAMASTER.postMasterProgramAutoComplete("", this.form.unit_kerja)
        
        // this.asyncFetchUrusan();
      //   DATAMASTER.postMasterUrusan('')
        this.onChangexInstansi();
        // this.getView();
    },
  }
